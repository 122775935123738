import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["source", "sharedText"]

	connect() {}

	share(event) {
		event.preventDefault()
		if (navigator.share) {
			navigator.share({
				title: event.currentTarget.getAttribute("data-title"),
				url: event.currentTarget.getAttribute("data-url")
			}).then(() => {
				console.log('Thanks for sharing!')
			})
			.catch(console.error);
		} else {
			this.copy(event)
		}
	}
	copy(e) {
		e.preventDefault()
		let originalLink = document.getElementById(e.currentTarget.getAttribute("id"))
		let originalHTML = e.currentTarget.innerHTML
		let url = e.currentTarget.getAttribute("data-url")
		let body = document.getElementsByTagName("body")[0]
		let tempInput = document.createElement("input")

		body.appendChild(tempInput)
		tempInput.setAttribute("value", url)
		tempInput.select()
		document.execCommand("copy")
		body.removeChild(tempInput)

		e.currentTarget.getElementsByClassName("icon-text")[0].innerHTML = "Copied to your clipboard!"
		setTimeout(() => this.replaceHTML(originalLink, originalHTML), 2000)
	}
	replaceHTML(e, html) {
		e.innerHTML = html
	}
}

