import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from "swiper"
Swiper.use([Navigation, Pagination])

export default class extends Controller {
  static targets = ["swiper"]
  static values = { options: Object }

  connect(){
    this.swiper = new Swiper(this.element, this.optionsValue);
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
