import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "query", "results", "clearSearch" ]

	initialize(){

	}

	searchData(event){
		let searchQuery = this.queryTarget.value
		const url = new URL(this.data.get("url"))

		url.searchParams.append("query", searchQuery)

		if (searchQuery == "") {
			this.resultsTarget.innerHTML = ""
			this.clearSearchTarget.classList.add('is-invisible')
		} else {
			this.clearSearchTarget.classList.remove('is-invisible')
			fetch(url, {})
			.then(response => response.text())
			.then(html => {
				this.resultsTarget.innerHTML = html
			})
			.catch(() => {})
		}

	}
	clearSearch(event) {
		this.queryTarget.value = ""
		this.resultsTarget.innerHTML = ""
		this.clearSearchTarget.classList.add('is-invisible')
	}
	relatedTermSearch(event) {
		this.queryTarget.value = event.currentTarget.textContent
		this.searchData(event)
	}
}
