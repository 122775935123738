import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = ["form", "cancel", "submit", "outputMessage"]
	static values = { url: String }

	connect() {}
	submit(event){
		let isValid = this.validateForm(this.formTarget);
		let feedbackModalButton = document.getElementById('feedback-modal-button')

		if (!isValid) {
		  event.preventDefault()
		} else {
			event.preventDefault()
			Rails.ajax({
			  url: this.urlValue,
			  type: "POST",
			  data: new FormData(this.formTarget),
			  success: (data) => {
				this.formTarget.classList.add('submitted')
				this.outputMessageTarget.classList.add('is-active')
				feedbackModalButton.disabled = true;
			  }
			})
		}
	}
	closeModal() {
		let feedbackModal = document.getElementById('feedback-modal')
		feedbackModal.classList.remove('is-active')
	}
	validateForm() {
		let isValid = true
		let errorMessages = document.querySelectorAll('.help.is-danger')

		if (errorMessages.length) {
			for (let i=0; i < errorMessages.length; i++) {
				errorMessages[i].parentNode.removeChild(errorMessages[i])
			}
		}

		let errorMessageEmpty = '<p class="help is-danger">This field is required.</p>'
		let requiredFieldSelectors = 'textarea:required, input:required'
		let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors)

		requiredFields.forEach((field) => {

			if (!field.disabled && !field.value.trim()) {
				field.classList.add('is-danger')
				field.parentNode.outerHTML += errorMessageEmpty

				isValid = false
			} else {
				field.classList.remove('is-danger')
			}
		});

		return isValid;
	}
}
