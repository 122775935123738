import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String
  }
  connect() {
  }
  toggleThumbnail() {
    let thumbnailEls = document.getElementsByClassName('thumbnail-preview')
    let thumbnailId = "content-thumbnail-"+event.currentTarget.dataset.id
    let thumbnailEl = document.getElementById(thumbnailId)

    for (let i=0; i < thumbnailEls.length; i++) {
        thumbnailEls[i].classList.remove('active')
    }
    thumbnailEl.classList.add('active')

    for (let i=0; i < event.currentTarget.parentElement.children.length; i++) {
        event.currentTarget.parentElement.children[i].classList.remove('active')
    }

    event.currentTarget.classList.add('active')
  }
}
