import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-email-field"
export default class extends Controller {
	static targets = ["email", "forgotpassword"]
	connect() {

	}
	fsmSelection(event) {
		event.preventDefault()
		this.addEmailToField(event, "fsm@samsungretailtraining.com")
	}
	rsaSelection(event) {
		event.preventDefault()
		this.addEmailToField(event, "rsa@samsungretailtraining.com")
	}
	showEmailField(event) {
		this.toggleActiveButton(event)
		this.emailTarget.value = ""
		this.emailTarget.parentElement.classList.remove("is-sr-only")
		this.forgotpasswordTarget.parentElement.classList.remove("is-sr-only")
		event.preventDefault()
	}
	addEmailToField(event, email) {
		this.toggleActiveButton(event)
		this.emailTarget.parentElement.classList.add("is-sr-only")
		this.emailTarget.value = email
		this.forgotpasswordTarget.parentElement.classList.add("is-sr-only")
	}
	toggleActiveButton(event) {
		let activeButton = document.querySelector('.is-primary')
		if(activeButton){
			activeButton.classList.remove('is-primary');
		  }
		  event.currentTarget.classList.add('is-primary');
	}
}
