import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="redactorx"
export default class extends Controller {
	initialize() {
		this.startRedactorX();

	}
	connect() {
		this.startRedactorX();
	}
	start() {
		this.startRedactorX();
	}
	startRedactorX() {
		RedactorX('.redactorx', {
			image: {
				upload: '/redactorx/image',
				url: false,
				data: {
					'authenticity_token': Rails.csrfToken()
				}
			},
			plugins: ['counter', 'alignment'],
			addbar: {
				hide: ['paragraph', 'pre', 'quote']
			},
			toolbar: {
				hide: ['html', 'deleted']
			},
			editor: {
				minHeight: '300px',
				maxHeight: '600px'
			}
		});
	}
}
