import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-published-date"
export default class extends Controller {
  static targets = ['statusSelectInput', 'publishedDateDateWrapper']

  connect(){
    if (this.hasStatusSelectInputTarget) {
      if (this.statusSelectInputTarget.value == 'scheduled') {
        this.publishedDateDateWrapperTarget.classList.remove('hidden')
      }
    }
  }

  toggle() {
    if (this.statusSelectInputTarget.value == 'draft' || this.statusSelectInputTarget.value == 'published') {
      this.publishedDateDateWrapperTarget.classList.add('hidden')
    } else {
      this.publishedDateDateWrapperTarget.classList.remove('hidden')
    }
  }
}
