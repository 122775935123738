import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hamburger", "mobileMenu", "darkLayer"]
  connect() {
  }

  toggle(event) {
    event.preventDefault()
    this.hamburgerTarget.classList.toggle('is-active')
    this.mobileMenuTarget.classList.toggle('is-active')
    this.darkLayerTarget.classList.toggle('is-active')
    var navBarLinks = document.getElementById('header-menu').getElementsByClassName('navbar-link')
    var navBarDropDowns = document.getElementById('header-menu').getElementsByClassName('navbar-dropdown')

    for (let i = 0; i < navBarLinks.length; i++) {
      navBarLinks[i].classList.remove('is-active')
    }
    for (let i = 0; i < navBarDropDowns.length; i++) {
      navBarDropDowns[i].classList.remove('is-active')
    }

  }
  toggleSubmenu(event) {
    event.preventDefault()

    var navBarParent = event.currentTarget.closest('.navbar-link')
    var subMenu = this.getNextSibling(navBarParent, ".navbar-dropdown")
    navBarParent.classList.toggle('is-active')
    subMenu.classList.toggle('is-active')

  }
  getNextSibling(elem, selector) {
    var sibling = elem.nextElementSibling;
    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = sibling.nextElementSibling
    }
  }
  getPreviousSibling(elem, selector) {
    var sibling = elem.previousElementSibling;
    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = sibling.previousElementSibling
    }
  }
  exitSubmenu(event) {
    var navBarDropdown = event.currentTarget.closest('.navbar-dropdown')
    var navBarParent = this.getPreviousSibling(navBarDropdown, ".navbar-link")
    navBarDropdown.classList.toggle('is-active')
    navBarParent.classList.toggle('is-active')
  }
}
