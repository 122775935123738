import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	connect() {}

	toggle(event) {
		if (this.isTouchDevice() && event.target.className != "sub-menu-link") {
			event.preventDefault()
			let headingEl = event.currentTarget.children[0]
			let subMenu = this.getNextSibling(headingEl, '.sub-menu')
			headingEl.classList.toggle('is-active')
			subMenu.classList.toggle('is-active')
		}

	}
	isTouchDevice() {
		return (('ontouchstart' in window) ||
			(navigator.maxTouchPoints > 0) ||
			(navigator.msMaxTouchPoints > 0))
	}
	getNextSibling(elem, selector) {
		var sibling = elem.nextElementSibling;
		while (sibling) {
		  if (sibling.matches(selector)) return sibling
		  sibling = sibling.nextElementSibling
		}
	  }
}
